import React from 'react'
import styled, { css } from 'styled-components'
import { Link, graphql, useStaticQuery } from 'gatsby'

// Elements
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import BlogBink, {
  useBlogBink,
  BlogBinkButton,
  BlogBinkFilter,
  BlogBinkPosts,
} from '@ubo/blog-bink'
import Plaatjie from '@ubo/plaatjie'

// Media
// import ArrowRight from 'img/arrow-right.inline.svg'

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Blog: React.FC<BlogProps> = ({ fields }) => {
  const {
    allWpPost,
    allWpCategory,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.blogQueryQuery>(graphql`
    query blogQuery {
      allWpPost(sort: {fields: recap___sortorder}) {
        edges {
          node {
            ...generalPostFragment
          }
        }
      }
      allWpCategory(filter: { name: { nin: "Uncategorized" } }) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `)

  const posts: unknown = allWpPost.edges

  return (
    <section>
      <BlogBink
        posts={posts as BlogBinkPosts}
        categories={allWpCategory.edges}
        id="blog"
        limit={Number(fields.limit)}
      >
        <div className="py-5 my-lg-5">
          <BlogFilters fields={fields} />
          <BlogGrid fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

const StyledBlogFilter = styled(BlogBinkFilter)<{ selected: boolean }>`
  border: 1px solid ${({ theme }) => theme.color.primary};
  display: inline-block;

  ${(props) =>
    props.selected &&
    css`
      background-color: ${({ theme }) => theme.color.primary};
      color: ${props.theme.color.light};
    `}

  ${(props) =>
    !props.selected &&
    css`
      color: ${props.theme.color.primary};
    `}

    @media (min-width: 992px) {
    font-size: ${({ theme }) => theme.font.size.medium};
    height: 30px;
    padding: 0 25px;
    border-radius: 15px;
    margin: 0 0.5rem 0.5rem 0;
  }

  @media (max-width: 991px) {
    font-size: ${({ theme }) => theme.font.size.small};
    height: 25px;
    padding: 0 15px;
    border-radius: 15px;
    margin: 0 0.1rem 0.25rem 0;
  }
`

const BlogFiltersTitle = styled.h2`
  font-weight: ${({ theme }) => theme.font.weight.bold};

  @media (min-width: 992px) {
    font-size: ${({ theme }) => theme.font.size.huge};
  }

  @media (max-width: 991px) {
    font-size: ${({ theme }) => theme.font.size.large};
  }
`

interface BlogFiltersProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

interface BlogFilterProps {
  node: {
    id: string
    name: string
  }
}

const BlogFilters: React.FC<BlogFiltersProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <div className="container mt-lg-5">
      <div className="row">
        <div className="col-sm-3 col-xl-2 pe-sm-0">
          <BlogFiltersTitle>{fields.filtertext}</BlogFiltersTitle>
        </div>
        <div className="col-sm-9 col-xl-10 ps-sm-4 mt-2 mt-sm-0">
          {blogBink.categories.map((category: BlogFilterProps) => (
            <StyledBlogFilter
              key={category.node.id}
              id={category.node.id}
              selected={blogBink.isSelected(category.node.id)}
              className="mr-1 mr-lg-3"
            >
              {category.node.name}
            </StyledBlogFilter>
          ))}
        </div>
      </div>
    </div>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <div className="container mt-lg-5">
      <div className="row pb-5">
        {blogBink.posts.map((post) => {
          const { node }: any = post

          return (
            <div key={post.node.id} className="col-md-4 mt-5">
              <BlogGridPost node={node} />
            </div>
          )
        })}
      </div>

      {blogBink.showMoreButton && (
        <div className="mt-5 text-center">
          <ButtonPrimary to="/" isCustom className="text-center">
            <BlogBinkButton className="w-100">
              {fields.loadmoretext}
            </BlogBinkButton>
          </ButtonPrimary>
        </div>
      )}
    </div>
  )
}

interface BlogGridPostProps {
  node: {
    id: string
    title: string
    uri: string
    recap: {
      thumbnail: any
    }
    categories: {
      nodes: Array<{ id: string }>
    }
  }
}

const PostImageWrapper = styled.div`
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  transform: translateZ(0);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  z-index: 1;

  @media (min-width: 992px) {
    height: 240px;
  }

  @media (max-width: 991px) {
    height: 200px;
  }

  @media (max-width: 767px) {
    height: 240px;
  }
`

const PostImage = styled(Plaatjie)`
  position: absolute !important;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  transition: all 0.2s ease-in-out;
`

const PostImageCategories = styled.div`
  position: relative;
  z-index: 2;
`

const PostImageCategory = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.light};
  font-size: ${({ theme }) => theme.font.size.tiny};
  border-radius: 10px;
  display: inline-block;
  padding: 0.1rem 0.75rem;
`

const PostInfo = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  border-bottom-right-radius: 15px;
  padding-top: 15px;
  margin-top: -15px;
  max-width: 90%;
  z-index: 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
`

const PostInfoTitle = styled.h3`
  color: ${({ theme }) => theme.color.dark};
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  transform: translateZ(0);
  text-overflow: ellipsis;

  @media (min-width: 992px) {
    font-size: ${({ theme }) => theme.font.size.big};
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  @media (max-width: 991px) {
    font-size: ${({ theme }) => theme.font.size.medium};
    font-weight: ${({ theme }) => theme.font.weight.semiBold};
  }
`

// const PostInfoArrow = styled.div``

const Post = styled(Link)`
  &:hover {
    & ${PostInfo} {
      background-color: ${({ theme }) => theme.color.dark};
    }

    & ${PostInfoTitle} {
      color: ${({ theme }) => theme.color.light};
      text-decoration: underline;
    }

    & ${PostImage} {
      transform: scale(1.05);
    }
  }
`

export const BlogGridPost: React.FC<BlogGridPostProps> = ({ node }) => {
  const blogBink = useBlogBink()

  const postCategories = node.categories.nodes.map(({ id }) => id)
  const categories = blogBink.categories.filter(
    ({ node: { id } }: any) => postCategories.indexOf(id) !== -1
  )

  return (
    <Post to={node.uri}>
      <PostImageWrapper>
        <PostImage image={node.recap.thumbnail} alt={node.title} />
        <PostImageCategories className="p-3">
          {categories.map((category: any) => (
            <PostImageCategory key={category.node.name} className="mr-2 mt-2">
              {category.node.name}
            </PostImageCategory>
          ))}
        </PostImageCategories>
      </PostImageWrapper>
      {/* <PostInfo>
        <PostInfoTitle className="px-3 py-3 mb-0">{node.title}</PostInfoTitle>
        <PostInfoArrow className="px-3">
          <ArrowRight />
        </PostInfoArrow>
      </PostInfo> */}
    </Post>
  )
}

export default Blog
